<template>
  <div class="container">
    <br><br><br><br>
    
    <div class="row">
    
      <div class="col-lg-6 text-center">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h4>DISPONIBILIDAD</h4>
          </div>
        </div>
        <Form ref="settingForm" @submit="handleRegister">
          <div v-if="!successful">
            <div class="form-group">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 text-left">
                    <span style="text-align:left;">Restaurante: </span>
                    <Field name="restaurantId" as="select" class="form-control">
                      <option value="" disabled selected>Restaurante: </option>
                      <option v-for="(option, index) in options" :key="index"  :value="option.id">
                        {{ option.name }}
                      </option>
                    </Field>
                      
                  </div>
                  <div class="col-lg-6 text-left">
                    <span>Setting: </span>
                    <div class="form-group">
                      <!-- <label for="password">Password</label> -->
                      <Field name="availability" type="number" class="form-control" />
                      <ErrorMessage name="availability" class="error-feedback" />
                    </div>
                  </div>
                  <div class="col-lg-6 text-left">
                    <span>Desde: </span>
                    <div class="form-group">
                      <Field name="startDate" type="date" :min="minDate" class="form-control" />
                      <ErrorMessage name="startDate" class="error-feedback" />
                    </div>
                  </div>
                  <div class="col-lg-6 text-left">
                    <span>Hasta: </span>
                    <div class="form-group">
                      <Field name="endDate" type="date" :min="minDate" class="form-control" />
                      <ErrorMessage name="endDate" class="error-feedback" />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <br>
                    <div class="form-group">
                      <button class="btn btn-primary btn-block" style="background-color:rgb(73,105,144);border-color:rgb(73,105,144);" :disabled="loading">
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        Registrar
                      </button>
                      <sub style="color:#007bff;">{{messageRegister}}</sub>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="col-lg-6">
        <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4>REPORTE DE RESERVACIONES</h4>
              </div>
            </div>
            <Form @submit="downloadFunction">
              <div v-if="!successful">
                <div class="form-group">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 text-left">
                        <span>Desde: </span>
                        <div class="form-group">
                          <Field name="start" type="date" class="form-control" />
                          <ErrorMessage name="start" class="error-feedback" />
                        </div>
                      </div>
                      <div class="col-lg-12 text-left">
                        <span>Hasta: </span>
                        <div class="form-group">
                          <Field name="end" type="date" class="form-control" />
                          <ErrorMessage name="end" class="error-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-lg-12">
                        <br>
                        <div class="form-group">
                          <button class="btn btn-primary btn-block" style="background-color:rgb(73,105,144);border-color:rgb(73,105,144);" :disabled="loading2">
                            <span
                              v-show="loading2"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            Descargar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
      </div>
      <div class="col-lg-12 text-center">
        <div class="row">
          <div class="col-lg-6">
        <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 text-center">
                <br><br>
                <h4>DISPONIBILIDAD DEL RESTAURANTE</h4>
              </div>
            </div>
            <Form @submit="availabilityRestaurant">
              <div v-if="!successful">
                <div class="form-group">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-12">
                        <br>
                        <div class="form-group">
                          <button class="btn btn-primary btn-block" style="background-color:rgb(73,105,144);border-color:rgb(73,105,144);" :disabled="loading2">
                            <span
                              v-show="loading2"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            Descargar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
      </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12 text-center">
                <br><br>
                <h4>ELIMINAR RESERVAS</h4>
              </div>
            </div>
            <Form @submit="deleteReservation">
              <div v-if="!successful">
                <div class="form-group">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 text-left">
                        <span>Id reserva: </span>
                        <div class="form-group">
                          <Field name="id" type="number" class="form-control" />
                          <ErrorMessage name="id" class="error-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-lg-12">
                        <br>
                        <div class="form-group">
                          <button class="btn btn-primary btn-block" style="background-color:rgb(73,105,144);border-color:rgb(73,105,144);" :disabled="loading3">
                            <span
                              v-show="loading3"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            Eliminar
                          </button>
                          <sub style="color:#007bff;">{{messageDelete}}</sub>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-12">

          
          <strong>Token:  </strong>

          <span>{{currentUser.token}}</span>
          {{currentUser.token.substring(0, 20)}} ... {{currentUser.token.substr(currentUser.token.length - 20)}}
          
        </div> -->
      </div>
    </div>
    <!-- <ul>
      <li v-for="role in currentUser.roles" :key="role">{{role}}</li>
    </ul> -->
  </div>
</template>

<script>
import UserService from "../services/user.service";
import { Form, Field, ErrorMessage } from "vee-validate";
import exportFromJSON from "export-from-json";
import * as yup from "yup";

export default {
  name: 'Profile',

  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
    });
    const schema2 = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
    });
    const schema3 = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
    });

    return {
      successful: false,
      loading: false,
      loading2: false,
      loading3: false,
      
      selected: "Restaurant",
      minDate: "",
      messageDelete: "",
      messageRegister: "",
      options: [],
      message: "",
      schema,
      schema2,
      schema3,
      
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    UserService.getRestaurants().then(
      (response) => {
        console.log(response.data)
        this.options = response.data;
        const today =  new Date()
        this.minDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0");
            // this.maxDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, "0") + "-" + (today.getDate()+1).toString().padStart(2, "0");
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  },
  methods: {
    availabilityRestaurant(dates) {
      // console.log(dates)
      UserService.getAvailabilityReservations(dates).then(
        (res)=> {
          // console.log("DATA: ", res.data)
           
          const arr = res.data
          const arrayReport =[]
          // console.log(arrayReport)
          
          const groupBy = (keys) => (array) =>
            array.reduce((objectsByKeyValue, obj) => {
              // console.log(keys)
              let value = keys.map((key) => obj[key]).join("/");
              // console.log(value)
              objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
              // console.log("enter", objectsByKeyValue[value])
              // console.log("enter2", objectsByKeyValue[value])
              objectsByKeyValue[value].sort((a,b) =>  b.id - a.id);
              // console.log("enter2", objectsByKeyValue[value])
              return objectsByKeyValue;
          }, {});

          const groupByBrandAndYear = groupBy(["date", "schedule",'restaurantId']);
          
          for (let [groupName, values] of Object.entries(groupByBrandAndYear(arr))) {
            const spliit = groupName.split("/")
            let nameRes = ""
            if (spliit[2] == "1"){
              nameRes = "Chianti"
            }else{
              nameRes = "Umami"
            }
            console.log(spliit[1])
            arrayReport.push(
              {
                "Fecha": spliit[0],
                "Hora": spliit[1],
                "Restaurante": nameRes,
                "Disponibilidad": values[0].counter,
              }
            )
            // console.log("counter:", values[0].counter)
            // console.log(`${groupName}: ${values.length}`);
          }
          console.log(arrayReport)
        //  const arr2 = clean.map(
        //       obj => {
        //           return {
        //               "Fecha" : obj.date,
        //               "Disponibilidad":obj.counter,
        //               "Restaurante":obj.restaurantId
        //           }
        //       }
        //   ); 
          
          // for (var i = 0; i < arrayReport.length; ++i) {
          //     if (arr2[i]['Restaurante'] === 1) {
          //         arr2[i]['Restaurante'] = 'Chianti';
          //     }else{
          //         arr2[i]['Restaurante'] = 'Umami';
          //     }
          // }

          
          arrayReport.sort((a,b) =>  new Date(b.Fecha) - new Date(a.Fecha));

          const data = arrayReport
          const fileName = "Disponibilidad";
          const exportType = exportFromJSON.types.csv;

          if (data) exportFromJSON({ data, fileName, exportType });
        },
        (err) => {
          console.log(err)
        }
      )
    },
    downloadFunction(dates) {
      UserService.getReservations(dates).then(
        (res)=> {

          const filteredData = res.data.filter(function(d){
            if (d.resDate >= dates.start && d.resDate <= dates.end){
              return d
            }
            
          });
          const data = filteredData
          const fileName = "Reservations";
          const exportType = exportFromJSON.types.csv;

          if (data) exportFromJSON({ data, fileName, exportType });
        },
        (err) => {
          console.log(err)
        }
      )
    },
    deleteReservation(idReservation) {
      this.loading3 = true
      UserService.deleteReservation(idReservation).then(
        (res)=> {
          console.log(res)
          this.loading3 = false
          this.messageDelete = "Reserva eliminada"
        },
        (err) => {
          console.log(err)
          this.loading3 = false
          this.messageDelete = "Id de reservacion inexistente"
        }
      )
    },
    handleRegister(user) {
      this.loading = true
      user.availability = Number(user.availability)
      user.restaurantId = Number(user.restaurantId)
      UserService.dateRange(user).then(
        (response) => {
          console.log(response)
          this.loading = false
          this.messageRegister = "Setting registrado"
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>